import React from "react"
import kebabCase from "lodash.kebabcase"
import { graphql, Link, useStaticQuery } from "gatsby"

import ConcatWords from "../utils/ConcatWords"
import formatDate from "../utils/formatDate"

const BlogFeatured = () => {
  const { mdx } = useStaticQuery(query)
  if (mdx === null) return false

  return (
    <article className="blog-list__post featured">
      <div className="blog-list__category">
        {mdx.frontmatter.category.map((cat, index, arr) => (
          <ConcatWords arrCount={arr.length} index={index} key={cat}>
            <Link to={`/blog/category/${kebabCase(cat)}`}>{cat}</Link>
          </ConcatWords>
        ))}
      </div>
      <Link className="hover:no-underline" to={mdx.fields.slug}>
        <h2 className="blog-list__post-title">{mdx.frontmatter.title}</h2>
      </Link>

      <div className="blog-list__date">{formatDate(mdx.frontmatter.date)}</div>
    </article>
  )
}

export default BlogFeatured

const query = graphql`
  query BlogFeatured {
    mdx(frontmatter: { featured: { eq: true }, disabled: { ne: true } }) {
      body
      fields {
        slug
      }
      frontmatter {
        title
        subtitle
        date(formatString: "MMMM DD, YYYY")
        category
        image {
          childImageSharp {
            fluid(maxWidth: 400, quality: 70) {
              src
            }
          }
        }
      }
    }
  }
`
