import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Pagination from "../components/pagination"
import Featured from "../components/featured"
import renderList from "../components/renderList"
import { Link } from "gatsby"
import kebabCase from "lodash.kebabcase"

const BlogPostList = ({ data, pageContext }) => {
  const { allMdx } = data
  const { currentPage, numPages, allCategories } = pageContext

  return (
    <Layout cssClass="blog-list">
      <Seo title="Blog" />
      <h1 className="blog-list__mainHeading">Blog</h1>
      <div className="md:flex">
        <div className="md:w-3/4 md:pr-4 mb-16 md:mb-0">
          <div className="blog-list__posts">
            {currentPage === 1 && <Featured />}
            {allMdx.edges.map(renderList)}
          </div>
          <Pagination currentPage={currentPage} numPages={numPages} />
        </div>
        <div className="md:w-1/4 md:pl-4">
          <h3 className="mt-0">Categories:</h3>
          <div className="blog-categories">
            {allCategories.map((cat, index) => (
              <Link
                key={`cat-${index}`}
                to={`/blog/category/${kebabCase(cat)}`}
                className="blog-categories__cat"
              >
                {cat}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPostList

export const query = graphql`
  query blogPostsList($skip: Int!, $limit: Int!) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { featured: { eq: false }, disabled: { ne: true } }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            subtitle
            date(formatString: "MMMM DD, YYYY")
            category
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 70) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
